"use client"; // Error components must be Client Components

import ErrorSplash from "@/components/ErrorSplash";
import { EVENT_LIBRARY } from "@/lib/constants";
import useAnalytics from "@/lib/hooks/useAnalytics";
import { useEffect } from "react";

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const track = useAnalytics();

  useEffect(() => {
    track(EVENT_LIBRARY.ENCOUNTERED_ERROR, {
      layout: "landing",
    });
  }, [error]);

  return <ErrorSplash />;
}
